<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
<!--							 <a-select-option :value="0">全部</a-select-option>-->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="线上会员卡号" name="cardNumber">
						<a-input placeholder="请输入" v-model:value="formState.cardNumber"></a-input>
					</a-form-item>
				</a-row>
				<a-row v-show="isShow">
					<a-form-item class="ui-form__item" label="会员手机号" name="phone">
						<a-input placeholder="请输入" maxLength="11" v-model:value="formState.phone"></a-input>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="会员昵称" name="nickname">
						<a-input placeholder="请输入" v-model:value="formState.nickname"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
              <a-select-option :value="3">PC购票</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="交易状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" placeholder="请选择交易状态" style="width: 190px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option value="CHECKOUT_SUCCESS">待使用</a-select-option>
							<a-select-option value="TRADE_FINISH">订单完成</a-select-option>
							<a-select-option value="AFTERSALE_FINISH">已售后</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单编号" name="orderNo">
						<a-input placeholder="请输入" v-model:value="formState.orderNo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="交易时间">
						<a-range-picker v-model:value="payTime" />
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['report_stored_sale_export']">
							<ExportReport type="baseRechargeCardVolume" :searchData="searchData"></ExportReport>
						</span>
						<span v-if="!isShow" class="ui-showMore" @click="onDisplay">展开<Icon icon="DownOutlined"></Icon></span>
						<span v-else class="ui-showMore" @click="onDisplay">收起<Icon icon="UpOutlined"></Icon></span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 2000 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						
						<template v-if="column.key === 'payStatus'">
							{{ transStatus(record.status) }}
						</template>
						
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
                <a-tag color="blue" v-if="record.source === 2"> 抖音小程序 </a-tag>
                <a-tag color="yellow" v-if="record.source === 3"> PC购票 </a-tag>
							</div>
						</template>

						<template v-if="column.key === 'ticketStatus'">
							{{ ['未取票', '出票中', '已取票'][record.ticketStatus - 1] }}
						</template>
						
						<template v-if="column.key == 'overTime'">
							<div>{{transDateTime(record.showStartTime)}}</div>
							<div> 至 </div>
							<div>{{transDateTime(record.showEndTime)}}</div>
						</template>

						<template v-if="column.key == 'createTime'">
							<div> {{transDateTime(record.createTime)}} </div>
						</template>

					</template>
					<template #summary v-if="list.length">
						<a-table-summary-row>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ count.balanceDeductionSum|| 0 }}元
							</a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ count.priceSum || 0 }} 元
							</a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getCardSaleList } from '@/service/modules/coupon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import moment from 'moment';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				formState: {
					cinemaId: '',
					couponType: 6,
					source: 0,
          saleType: 3,
          status:''
				},
				payTime: [],
				count: {},
				showModal: false,
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '订单编号',
					dataIndex: 'orderNo'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '线上会员卡号',
					dataIndex: 'cardNumber'
				}, {
					title: '会员昵称',
					dataIndex: 'nickname'
				}, {
					title: '会员手机号',
					dataIndex: 'userPhone'
				}, {
					title: '影片名称',
					dataIndex: 'filmName'
				}, {
					title: '线上会员卡消费总额',
					dataIndex: 'balanceDeduction'
				}, {
					title: '座位价格',
					dataIndex: 'price'
				}, {
					title: '交易状态',
					key: 'payStatus'
				}, {
					title: '取票状态',
					key: 'ticketStatus'
				}, {
					title: '交易时间',
					key: 'createTime'
				}, {
					title: '放映起始/结束时间',
					key: 'overTime'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				id: 0,
        searchData: {
        },
				isShow: false,
				organizationList: [],
				cinemaAllList: [],
			}
		},
		created(){
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.status = this.searchData.status ? this.searchData.status : undefined;
				if (this.payTime && this.payTime.length) {
					this.searchData.startPayTime = this.payTime[0].startOf('days').unix();
					this.searchData.endPayTime = this.payTime[1].endOf('days').unix();
				}
				this.getData();
			},
			reset() {
				this.payTime = [];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData(){
				if(this.searchData.exchangeTime && this.searchData.exchangeTime.length == 2){
					this.searchData.startTime = moment(this.formState.exchangeTime[0].startOf('day')).unix();
					this.searchData.endTime = moment(this.formState.exchangeTime[1].endOf('day')).unix();
				}
				delete this.searchData.exchangeTime;
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				}
				this.loading = true;
				try {
					let {data} = await getCardSaleList(postData);
					this.loading = false;
					this.count = data.list[0];
					this.list = data.list.slice(1);
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(() => {

				})
			},
			onDisplay(){
				this.isShow = !this.isShow;
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					this.loading = false;
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = '';
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			transStatus(val, type) {
				if (val === 'CHECKOUT_SUCCESS') {
					return '待使用';
				}
				if (val === 'AFTERSALE_FINISH') {
					return '已售后';
				}
				if (val === 'TRADE_FINISH') {
					return '订单完成';
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-batch{
		margin-right: 10px;
	}
	.ui-showMore{
        color: #6699ff;
        margin-left: 10px;
		cursor: pointer;
    }
</style>
